
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12">
        <h3>Report Exports</h3>
      </div>
    </div>

    <br/>
    <b-table striped hover :items="reportExports" :fields="columns">
      <template v-slot:cell(downloadableLink)="data">
        <a style="text-decoration: underline;font-weight: bold;" v-if="data.item.downloadableLink" :href="data.item.downloadableLink">Download</a>
      </template>
    </b-table>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"
  import Multiselect from 'vue-multiselect';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  data() {
    return {
      reportExports:[],
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["created","name","recipient","downloadableLink","reportType"],
      loyaltyRule:{}
    };
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getReportExportsData();
  },
  methods: {
    ...mapActions(["getReportExports"]),

    getReportExportsData(){
      let self = this;
      this.getReportExports()
        .then(function (reportExports) {
          self.reportExports = reportExports;
        })
        .catch(function (error) {


        })
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
